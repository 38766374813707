.modelibiopsikosocial-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    p {
        color: #464646;
        font-family: 'roboto-light';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
    }

    h1 {
        color: #464646;
        font-family: 'roboto-bold';
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }

    .buttons-container-mod {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 18px;
        align-self: stretch;
        .btn-container-mod {
            display: flex;
            padding: 10px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: #1C68DA;
            border: none;
            outline: none;
            .btn-text-mod {
                color: #fff;
                font-family: 'poppins-medium';
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                text-transform: uppercase;
            }

            &:hover {
                cursor: pointer;
                background: #E73529;
                transition: ease-in-out 0.3s;
            }
            &.disabled{
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }

    .img-container {
        align-self: stretch;
        border: 1px solid #D1D3D4;
        border-radius: 8px;
        padding: 18px;
        height: 412px;
        .modelibio-img {
            height: 100%;
            width: 100%;
            flex-shrink: 0;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        .img1 {
            background-image: url('./../../../../../assets/screens/module-assets/modeli-bio-1.svg');
        }
        .img2 {
            background-image: url('./../../../../../assets/screens/module-assets/modeli-bio-2.svg');
        }

        .img3 {
            background-image: url('./../../../../../assets/screens/module-assets/modeli-bio-3.svg');
        }

        .img4 {
            background-image: url('./../../../../../assets/screens/module-assets/modeli-bio-4.svg');
        }

        .img5 {
            background-image: url('./../../../../../assets/screens/module-assets/modeli-bio-5.svg');
        }

        .img6 {
            background-image: url('./../../../../../assets/screens/module-assets/modeli-bio-6.svg');
        }

        .img7 {
            background-image: url('./../../../../../assets/screens/module-assets/modeli-bio-7.svg');
        }

        .img8 {
            background-image: url('./../../../../../assets/screens/module-assets/modeli-bio-8.svg');
        }

        @media(max-width: 768px) {
            height: 278px;
        }
    }

    .modeli-container {
        font-family: 'roboto-light';
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        gap: 24px;

        span {
            font-family: 'roboto-bold';
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
        }
    }

    .modeli-knf {
        font-family: 'roboto-light';
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
    }

    .list-content{
        display: flex;
        padding: 18px 12px;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;
        border-radius: 8px;
        background: #E8F3FF;
        box-shadow: -2px 4px 8px 0px rgba(0, 0, 0, 0.25);
        ol,ul{
            display: flex;
            flex-direction: column;
            gap: 20px;
            li{
                color: #464646;
                font-family: 'roboto-light';
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 150%;
                margin: 0px 12px 0px 25px !important;  
                span{
                    color: #464646;
                    font-family: 'roboto-bold';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%; 
                }
            }
        }
    }

    .burimet-content {
        align-self: stretch;
        p{
            color: #464646;
            font-family: 'roboto-light';
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%;
            span {
                color: #1C68DA;
                font-family: 'roboto-bold';
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
            }
        }
    }

    .funksionimi-header {
        font-family: 'roboto-bold';
        font-size: 28px;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: left;
    }

    .content-card-container{
        display: flex;
        padding: 18px 12px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        gap: 20px;
        background-color: #FFE8F0;
        border-radius: 8px;
        box-shadow: -2px 4px 8px 0px rgba(0, 0, 0, 0.25);
        p{
            align-self: stretch;
            color: #000;
            font-family: 'roboto-medium';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; 
        }
        .list{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 18px;
            align-self: stretch;
            .list-item{
                display: flex;
                align-items: flex-start;
                .list-bullet{
                    margin: 7.5px 12px 7.5px 0px; 
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #E73529;
                }
                p{
                    flex: 1 0 0;
                    color: #464646;
                    font-family: 'roboto-light';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%; 
                }
            }
        }
    }

    .semundja-list-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: #E8F3FF;
        border-radius: 8px;

        ul {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 18px;

            li {
                color: #464646;
                font-family: 'roboto-light';
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 150%;
                margin-left: 25px !important;
            }
        }

    }

    .text-container {
        align-self: stretch;
        height: auto;
        border: 1px solid gray !important;
        border-radius: 8px;
        padding: 18px;
        box-shadow: -2px 4px 8px 0px #0000001A;

        p {
            color: #000;
            font-family: 'roboto-light';
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%;
            span{
                color: #000;
                font-family: 'roboto-bold';
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
            }
        }
    }

    .plotesoni-content {
        display: flex;
        align-self: stretch;
        padding: 18px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 8px;
        border: 1px solid #D1D3D4;
        background: #E8F3FF;

        h1{
            font-family: 'roboto-bold';
            font-size: 28px;
            font-weight: 700;
            line-height: 34px;
        }

        .paragraph-elements-container{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 18px;
            align-self: stretch;
            p{
                color: #464646;
                font-family: 'roboto-light';
                font-size: 14px;
                font-style: italic;
                font-weight: 300;
                line-height: 120%; 
            }
        }

        .elements {
            display: flex;
            align-items: flex-start;
            align-content: flex-start;
            gap: 12px;
            align-self: stretch;
            flex-wrap: wrap;

            .gjendjes-shëndetësore,
            .funksionimi,
            .aftësia-e-kufizuar,
            .faktorëve,
            .funksione,
            .draggable-item {
                display: flex;
                padding: 10px 12px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 8px;
                border: 1px solid #D1D3D4;
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
                p{
                    color: #000;
                    font-family: 'roboto-light';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%; 
                }
            }
        }

        .drop-content {
            align-self: stretch;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            p {
              color: #464646;
              font-family: 'roboto-light';
              font-size: 14px;
              font-style: normal;
              font-weight: 300;
              line-height: 300%;
              display: inline; /* Set paragraphs to display inline */
            }
            input{
                background-color: transparent;
                border: none;
                border-bottom: 1px solid #464646;
                height: 27px;
                margin: 0px 5px;
                outline: none;
                    color: #464646;
                    font-family: 'roboto-light';
                    font-size: 14px;
                    font-style: normal;
                    line-height: 50%;
            }

              .sm-input{
                width: 135px;
              }

              .lg-input{
                width: 160px;
              }

          }
    }

    .table-content {
        align-self: stretch;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 28px;

        .list {
            display: flex;
            align-self: stretch;
            flex-direction: column;
            align-items: flex-start;

            .list-item {
                display: flex;
                align-self: stretch;
                border: 1px solid #D1D3D4;
                border-bottom: none;
                &:last-child {
                    border-bottom: 1px solid #D1D3D4;
                }
                .list-paragraph-container {
                    display: flex;
                    padding: 18px;
                    justify-content: center;
                    gap: 10px;
                    border-right: 1px solid #D1D3D4;
                    mix-blend-mode: darken;
                    flex: 1 0 0;
                    .list-paragraph-number {
                        display: flex;
                        height: 100%;
                        align-items: flex-start;

                        .number-of-paragraph {
                            color: #000;
                            font-family: 'roboto-light';
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 150%;
                        }
                    }

                    .content-paragraph-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex: 1 0 0;
                        height: 100%;

                        .content-of-paragraph {
                            flex: 1 0 0;
                            color: #000;
                            font-family: 'roboto-light';
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 150%;

                            span {
                                color: red;
                                font-family: 'roboto-bold';
                                font-weight: 700;
                            }
                        }
                    }

                    .content-of-paragraph {
                        flex: 1 0 0;
                        color: #000;
                        font-family: 'roboto-light';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 150%;

                        span {
                            color: red;
                            font-family: 'roboto-bold';
                            font-weight: 700;
                        }
                    }
                }

                .check-container,
                .stop-container {
                    display: flex;
                    padding: 18px;
                    width: 97px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    align-self: stretch;
                    mix-blend-mode: darken;
                }

                .check-container{
                    border-right: 1px solid #D1D3D4;
                    &:hover{
                        background-color: #99BF5F;
                        transition: ease-in-out 0.3s;
                        cursor: pointer;
                        .check-icon{
                            transition: ease-in-out 0.3s;
                            background-image: url('./../../../../../assets/screens/module-assets/white-checked.svg');
                        }
                    }
                    &.active{
                        background-color: #99BF5F;
                        transition: ease-in-out 0.3s;
                        cursor: pointer;
                        .check-icon{
                            transition: ease-in-out 0.3s;
                            background-image: url('./../../../../../assets/screens/module-assets/white-checked.svg');
                        }
                    }
                }

                .stop-container{
                    &:hover{
                        transition: ease-in-out 0.3s;
                        background-color: #E02216;
                        cursor: pointer;
                        .stop-icon{
                            transition: ease-in-out 0.3s;
                            background-image: url('./../../../../../assets/screens/module-assets/white-stop.svg');
                        }
                    }

                    &.active{
                        transition: ease-in-out 0.3s;
                        background-color: #E02216;
                        cursor: pointer;
                        .stop-icon{
                            transition: ease-in-out 0.3s;
                            background-image: url('./../../../../../assets/screens/module-assets/white-stop.svg');
                        }
                    }
                }

                .icona {
                    width: 33px;
                    height: 33px;
                    background-size: cover;
                    background-repeat: no-repeat;
                }

                .check-icon {
                    background-image: url('./../../../../../assets/screens/module-assets/checked-icon.svg');
                }

                .stop-icon {
                    background-image: url('./../../../../../assets/screens/module-assets/stop-icon.svg');
                }

                @media(max-width: 576px){
                    flex-wrap: wrap;
                    .list-paragraph-container{
                        width: 100%;
                        border-right: none;
                        border-left: none;
                    }
                    .check-container,.stop-container{
                        width: 100%;
                        border-top: 1px solid #D1D3D4;
                        border-right: none;
                        border-left: none;
                    }
                }
            }
        }
    }
}