.mekanizmat-container{
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    p{
        color: #464646;
        font-family: roboto-light;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; 
    }
    // .image-container{
    //     background-image: url('./../../../../../assets/screens/module-assets/learning.jpg');
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     align-self: stretch;
    //     height: 719px;
    // }
    @media(max-width: 575px){
        .image-container{
            height: 100%;
            width: 100%;
        }
    }
    .image-subcontainer{
        background-image: url('./../../../../../assets/screens/module-assets/learning.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 719px;
        @media(max-width: 1400px){
            height: 618px;
        }
        @media(max-width: 1200px){
            height: 517px;
        }
        @media(max-width: 992px){
            height: 701px;
        }
        @media(max-width: 768px){
            height: 520px;
        }
        @media(max-width: 575px){
            margin: auto;
            width: 351px;
            height: 353px;
        }
        @media(max-width: 400px) {
            height: 272px;
            width: 270px;
        }
    }
    .content-card-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        gap: 18px;
        .list{
            display: flex;
            padding: 18px 12px;
            flex-direction: column;
            align-items: flex-start;
            gap: 18px;
            align-self: stretch;
            border-radius: 8px;
            background: #EFE8FF;
            box-shadow: -2px 4px 8px 0px rgba(0, 0, 0, 0.25);
            .list-item{
                display: flex;
                align-items: flex-start;
                .list-bullet{
                    margin: 7.5px 12px 7.5px 0px; 
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #E73529;
                }
                p{
                    flex: 1 0 0;
                    color: #464646;
                    font-family: roboto-light;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%; 
                }
            }
        }
    }
}