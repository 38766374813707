.vazhdimesiafunksionit-container{
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 38px;
    align-self: stretch;
    p{
        color: #464646;
        font-family: 'roboto-light';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; 
    }
    .main-content-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 28px;
        align-self: stretch;
        .img-container{
            width: 386px;
            height: 72px;
            background-image: url('./../../../../../assets/screens/module-assets/vazhdimesia-funksionimit.svg');
            background-size: cover;
            background-repeat: no-repeat;
            @media(max-width: 400px){
                width: 290px;
                height: 50px;
            }
        }
    }
    .table-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 18px;
        align-self: stretch;
        .table-title{
            align-self: stretch;
            color: #464646;
            font-family: 'roboto-bold';
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; 
        }

        .table{
            display: flex;
            align-self: stretch;
            align-items: flex-start;
            margin: 0 !important;
            gap: 22px;
            .table-left,
            .table-right{
                display: flex;
                padding: 18px 12px;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
                flex: 1 0 0;
                border-radius: 8px;
                background: #E8F3FF;
                box-shadow: -2px 4px 8px 0px rgba(0, 0, 0, 0.25);
                .elements {
                    display: flex;
                    align-items: flex-start;
                    align-content: flex-start;
                    gap: 12px;
                    align-self: stretch;
                    flex-wrap: wrap;
                    .draggable-item {
                        background-color: transparent;
                        display: flex;
                        padding: 10px 12px;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        border-radius: 8px;
                        border: 1px solid #D1D3D4;
                        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
                        p{
                            color: #000;
                            font-family: 'roboto-light';
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 150%; 
                        }
                    }
                }
                input{
                        background-color: transparent;
                        border: none;
                        border-bottom: 1px solid #D1D3D4;;
                        height: 27px;
                        outline: none;
                        color: #464646;
                        font-family: 'roboto-light';
                        font-size: 14px;
                        font-style: normal;
                        line-height: 50%;
                        // width: 311.25px;
                        // width: 50%;
                        align-self: stretch;
                    }
            }
            .table-left,
            .table-right{
                .checkbox-container{
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    align-self: stretch;
                    gap: 12px;
                    .droppable-input{
                        background-color: transparent;
                        padding: 0 !important;
                        width: 50%;
                        .sm-input{
                            width: 100%;
                        }
                    }
                    label{
                        padding: 0 !important;
                        width: 50%;
                        color: #000;
                        color: #464646;
                        text-align: right;
                        font-family: 'roboto-regular';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 150%; 
                        text-align: right;
                        background-color: transparent;
                        span{
                            font-size: 16px;
                            color: #E73529;
                            font-family: 'roboto-bold';
                        }
                    }
                    .input-checkbox-displayed{
                        display: flex;
                        text-align: center;
                        outline: none;
                        width: 30px;
                        height: 30px;
                        flex-shrink: 0;
                        border-radius: 8px;
                        border: 1px solid #D1D3D4;
                        background-color: transparent;
                        &:hover{
                            cursor: pointer;
                            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
                        }
                    }
                    &:nth-child(6){
                        height: 58px;
                    }
                }
            }
            .table-right{
                .checkbox-container{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    align-self: stretch;
                    gap: 12px;
                    label{
                        color: #000;
                        color: #464646;
                        text-align: right;
                        font-family: 'roboto-regular';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 150%; 
                        text-align: left;
                        background-color: transparent;
                        
                    }
                    .input-checkbox-displayed{
                        display: flex;
                        width: 30px;
                        height: 30px;
                        flex-shrink: 0;
                        border-radius: 8px;
                        border: 1px solid #D1D3D4;
                        background-color: transparent;
                        &:hover{
                            cursor: pointer;
                            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
                        }
                    }
                }
            }
        }

        .table-buttons-container{
            display: flex;
            align-items: flex-start;
            gap: 16px;
            .table-button{
                display: flex;
                padding: 10px 24px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 8px;
                background: #1C68DA;
                border: none;
                outline: none;
                p{
                    color: #FFF;
                    font-family: poppins-medium;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    text-transform: uppercase;
                }
                &:hover {
                    cursor: pointer;
                    background: #E73529;
                    transition: ease-in-out 0.3s;
                }
                &.disabled{
                    opacity: 0.5;
                    cursor: not-allowed;
                }
            }
        }
    }
}