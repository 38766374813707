.main-container{
    align-self: stretch;
    .list{
        display: flex;
        flex-direction: column;
        gap: 33px;
        .list-item{
            display: flex;
            align-items: flex-start;
            .list-bullet{
                margin: 7.5px 12px 7.5px 0px; 
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #E73529;
            }
            p{
                flex: 1 0 0;
                color: #464646;
                font-family: roboto-light;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 150%; 
            }
        }
    }
    @media( max-width: 400px){
        width: 100%;
        justify-content: center;
        .banner-image-container{
            height: 229px;
            width: 290px;
        }
    }
}