.niveletarsimit-container{
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 38px;
    p{
        color: #464646;
        font-family: 'roboto-light';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; 
    }
    .niveletarsimit-top{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 26px;
        align-self: stretch;
        .nivelet-img-container{
            align-self: stretch;
            height: 580px;
            .nivelet-img{
                background-image: url('./../../../../../assets/screens/module-assets/nivelet.svg');
                background-size: cover;
                background-repeat: no-repeat;
                align-self: stretch;
                height: 100%;
                @media(max-width: 400px) {
                height: 180px !important;
                width: 260px !important;
            }
            }
            @media(max-width: 1400px){
                height: 499px;
            }
            @media(max-width: 1200px){
                height: 418px;
            }
            @media(max-width: 992px){
                height: 473px;
            }
            @media(max-width: 768px){
                height: 351px;
            }
            @media(max-width: 576px){
                .nivelet-img{
                    width: 351px;
                    height: 238px;
                }
                align-self: stretch;
                display: flex;
                justify-content: center;
                height: 238px;
            }
            
        }
        .niveletarsimit-inclusive-container{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 38px;
            h3{
                color: #464646;
                font-family: 'roboto-bold';
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%; 
            }
            .niveletarsimit-inclusive-subcontainer{
                p{
                    color: #464646;
                    font-family: 'roboto-light';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%; 
                    span{
                        color: #464646;
                        font-family: 'roboto-bold';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 150%;
                    }
                }
            }
        }
        .niveletarsimit-dropdowns-container{
            display: flex;
            align-items: flex-start;
            align-self: stretch;
            justify-content: space-between;
            flex-wrap: wrap;
            @media(max-width: 768px){
                gap: 4px;
            }
        }

        .highlighted-paragraph-container{
            display: flex;
            align-self: stretch;
            padding: 12px 18px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            border: 1px solid #B3B3B3;
            background: #FFF;
            box-shadow: -2px 4px 8px 0px rgba(0, 0, 0, 0.10);
            span{
                color: #464646;
                font-family: 'roboto-bold';
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
            }
        }
        @media(max-width: 575px){
            width: 100% !important;
        }
    }
    .niveletarsimit-bottom{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 28px;
        h3{
            flex: 1 0 0;
            color: #464646;
            font-family: 'roboto-medium';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
        }
        .niveletarsimit-table{
            display: flex;
            align-items: flex-start;
            gap: 20px;
            align-self: stretch;
            .niveletarsimit-table-left,
            .niveletarsimit-table-right{
                width: calc(50% - 10px);
                display: flex;
                flex-direction: column;
            }
            .niveletarsimit-table-header{
                display: flex;
                padding: 12px 18px;
                align-items: center;
                gap: 10px;
                flex: 1 0 0;
                align-self: stretch;
                border-radius: 8px 8px 0px 0px;
                border-bottom: 1px solid #000;
                background: #E73529;
                p{  
                    flex: 1 0 0;
                    color: #FFF;
                    font-family: 'roboto-medium';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%; 
                }
                @media(max-width: 768px){
                    p{
                        font-size: 14px;
                    }
                }
            }

            .niveletarsimit-table-left{
                @media(max-width: 768px){
                    .left-header{
                        padding-bottom: 33px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            
            .niveletarsimit-table-left-row,
            .niveletarsimit-table-right-row{
                display: flex;
                padding: 12px 18px;
                align-items: flex-start;
                gap: 10px;
                flex: 1 0 0;
                align-self: stretch;
                border: 1px solid #D1D3D4;
                border-top: none;
                background: #FFF;
                p{  
                    flex: 1 0 0;
                    color: #464646;
                    font-family: 'roboto-light';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%; 
                    @media( max-width: 518px){
                        font-size: 12px;
                    }
                }
            }
            .niveletarsimit-table-left-row{
                &:first-child,
                &:nth-child(2){
                    height: 88px;
                }
                &:nth-child(4){
                    height: 151px;
                }
                &:nth-child(5){
                    height: 109px;
                }
            }

            .niveletarsimit-table-right-row{
                &:nth-child(3),
                &:nth-child(7){
                    height: 67px;
                }
            }

            @media( max-width: 1400px){
                .niveletarsimit-table-left-row{
                    &:nth-child(2){
                        height: 109px;
                    }
                    &:nth-child(4){
                        height: 172px;
                    }
                    &:nth-child(5){
                        height: 130px;
                    }
                    &:nth-child(8){
                        height: 88px;
                    }
                }
            }

            @media( max-width: 1200px){
                .niveletarsimit-table-left-row{
                    &:nth-child(1){
                        height: 109px;
                    }
                    &:nth-child(3){
                        height: 88px;
                    }
                    &:nth-child(4){
                        height: 214px;
                    }
                    &:nth-child(5){
                        height: 151px;
                    }
                    &:nth-child(6){
                        height: 88px;
                    }
                }
                .niveletarsimit-table-right-row{
                    &:nth-child(3){
                        height: 88px;
                    }
                    &:last-child{
                        height: 88px;
                    }
                }
            }

            @media( max-width: 992px){
                .niveletarsimit-table-left-row{
                    &:nth-child(1){
                        height: 88px;
                    }
                    &:nth-child(4){
                        height: 172px;
                    }
                    &:nth-child(5){
                        height: 130px;
                    }
                    // &:nth-child(6){
                    //     height: 88px;
                    // }
                }
                .niveletarsimit-table-right-row{
                    &:last-child{
                        height: 67px;
                    }
                }
            }

            @media( max-width: 767px){
                .niveletarsimit-table-left-row{
                    &:first-child{
                        height: 109px;
                    }
                    &:nth-child(2){
                        height: 130px;
                    }
                    &:nth-child(4){
                        height: 256px;
                    }
                    &:nth-child(5){
                        height: 193px;
                    }
                    &:nth-child(6){
                        height: 109px;
                    }
                    &:nth-child(8){
                        height: 109px;
                    }
                }

                .niveletarsimit-table-right-row{
                    &:nth-child(3),
                    &:nth-child(7){
                        height: 88px;
                    }
                    &:nth-child(9){
                        height: 88px;
                    }
                    &:last-child{
                        height: 88px;
                    }
                }
            }

            @media( max-width: 576px){
                .niveletarsimit-table-left-row{
                    &:first-child{
                        height: 109px;
                    }
                    &:nth-child(2){
                        height: 130px;
                    }
                    &:nth-child(4){
                        height: 235px;
                    }
                    &:nth-child(5){
                        height: 172px;
                    }
                    &:nth-child(6){
                        height: 88px;
                    }
                    &:nth-child(8){
                        height: 109px;
                    }
                }

                .niveletarsimit-table-right-row{
                    &:nth-child(3){
                        height: 88px;
                    }
                    &:nth-child(7){
                        height: 67px;
                    }
                    &:nth-child(9){
                        height: 88px;
                    }
                }
            }
            @media(max-width: 400px) {
                .niveletarsimit-table-right-row{
                    &:nth-child(9){
                        height: auto;
                    }
                }
            }
            
        }
    }
}