.module-container{
    align-self: stretch;
    height: 100%;
    .module-banner-container{
        background-color: #FFF0E8;
        padding: 62px 0px;
        .module-banner-subcontainer{
            align-self: stretch;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .module-banner-left{
                display: flex;
                flex-direction: column;
                gap: 25px;
                .module-banner-title{
                    align-self: stretch;
                    color: #464646;
                    font-family: roboto-bold;
                    font-size: 64px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%; 
                }
                .module-banner-description{
                    align-self: stretch;
                    color: #464646;
                    font-family: roboto-bold;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                }
                .module-banner-time{
                    display: flex;
                    align-items: flex-start;
                    gap: 16px;
                    .clock-img{
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        width: 20px;
                        height: 20px;
                        background-color: transparent;
                    }
                    p{
                        color: #000;
                        font-family: roboto-light;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 150%;
                    }
                }
                @media(max-width: 992px){
                    gap: 18px;
                    .module-banner-title{
                        font-size: 45px;
                    }
                    .module-banner-description{
                        font-size: 22px;
                    }
                }

                @media(max-width: 767px){
                    align-items: center;
                    .module-banner-title{
                        font-size: 37px;
                        text-align: center;
                    }
                    .module-banner-description{
                        font-size: 18px;
                        text-align: center;
                    }
                    .module-banner-time{
                        align-items: center;
                        gap: 8px;
                        p{
                            font-size: 12px;
                        }
                    }
                    .module-banner-register-btn{
                        width: 100%;
                        justify-content: center;
                    }
                }
            }
            .module-banner-right{
                display: flex;
                justify-content: flex-end;
                align-self: stretch;
                align-items: center;
                .module-banner-image-container{
                    background-image: url('./../../assets/screens/home-assets/home-banner.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 436px;
                    width: 562px;
                    flex-shrink: 0;
                    @media( max-width: 1200px){       
                            height: 376px;
                            width: 482px;
                    }
                    @media( max-width: 992px){       
                        height: 287px;
                        width: 370px;
                    }
                }
                @media (max-width: 767px){
                    width: 100%;
                    justify-content: center;
                    .module-banner-image-container{
                        height: 287px;
                        width: 370px;
                    }
                }
                @media( max-width: 400px){
                    width: 100%;
                    justify-content: center;
                    .module-banner-image-container{
                        height: 229px;
                        width: 290px;
                    }
                }
            }
            @media(max-width: 767px){
                flex-direction: column;
                gap: 20px;
            }
        }
    }
    .module-content-container{
        display: flex;
        align-items: center;
        justify-content: center;
        .module-content-subcontainer{
            display: flex;
            padding: 24px;
            flex-direction: column;
            align-items: flex-start;
            gap: 6px;
            border-radius: 8px;
            border: 1px solid #D1D3D4;

            .module-content-title{
                align-self: stretch;
                color: #464646;
                font-family: roboto-bold;
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; 
                margin: 14px 0px 8px 0px !important;
            }

            .content-container{
                display: flex;
                padding: 12px 24px;
                justify-content: space-between;
                align-items: center;
                align-self: stretch;
                border-radius: 8px;
                border: 1px solid rgba(209, 211, 212, 0.24);
                background: #FFF;
                cursor: pointer;
                
                .content-title{
                    flex: 1 0 0;
                    color: #B8B8B8;
                    font-family: roboto-light;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%;
                }

                .burimet-title{
                    color: #1C68DA;
                    text-decoration: none;
                    &:hover{
                        text-decoration: underline;
                    }
                }

                .content-btn-container{
                    display: flex;
                    padding: 10px 24px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 8px;
                    border: 1px solid #E73529;
                    background-color: #FFF;
                    .content-btn-text{
                        color: #E73529;
                        font-family: poppins-medium;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px; 
                        text-transform: uppercase;
                    }
                    .content-btn-arrow-icon{
                        width: 12px;
                        height: 8px;
                        background-image: url('./../../assets/screens/module-assets/red-right-arrow.svg');
                        background-position: center;
                        background-repeat: no-repeat;
                    }

                    &:hover{
                        transition: ease-in-out 0.3s;
                        cursor: pointer;
                        border: 1px solid #E73529;
                        background-color: #E73529;
                        .content-btn-text{
                            color: #FFF;
                            transition: ease-in-out 0.3s;
                        }
                        .content-btn-arrow-icon{
                            background-image: url('./../../assets/screens/module-assets/white-right-arrow.svg');
                            transition: ease-in-out 0.3s;
                        }
                    }
                }
            }

            .active{
                border: 1px solid #D1D3D4;
                .content-title{
                    color: #464646;
                    font-family: roboto-bold;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%; /* 21px */
                }
            }

            @media(max-width: 575px){
                width: 85%;
                padding: 18px;
                .module-content-title{
                    font-size: 22px;
                    margin: 12px 0 6px 0 !important;
                }
                .content-container{
                    flex-direction: column;
                    gap: 10px;
                    .content-title{
                        font-size: 16px;
                        text-align: center;
                    }
                    .content-btn-container{
                        align-self: stretch;
                        .content-btn-text{
                            font-size: 12px;
                        }
                    }
                
                }
            }
        }
    }
}