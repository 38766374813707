.cfareeshteaftesiakufizuar-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    padding: 0 !important;
    p{
        color: #464646;
        font-family: 'roboto-light';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; 
        span{
            font-family: 'roboto-bold';
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
        }
    }

    .paragraphs-container{
        display: flex;
        flex-direction: column;
        gap: 28px;
    }

    .barrierat-ilustrated-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 18px;
        .barrierat-ilustrated-subcontainer{
            display: flex;
            align-self: stretch;
            justify-content: space-between;
            height: 100%;
            flex-wrap: wrap;
            .barriera-container{
                display: flex;
                // flex: 1 0 0;
                flex-direction: column;
                align-items: center;
                gap: 18px;
                .title-container{
                    display: flex;
                    flex: 1 0 0;
                    align-items: flex-end;
                    .title-subcontainer{
                        padding: 12px;
                        align-items: center;
                        gap: 10px;
                        border-radius: 8px;
                        border: 1px solid #000;
                        .title{
                            color: #464646;
                            font-family: 'roboto-bold';
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 150%;
                            @media(max-width: 1400px){
                                font-size: 12px;
                            }
                            @media(max-width: 576px){
                                font-size: 10px;
                            }
                        }
                    }
                }
                @media(max-width: 1200px){
                       width: 33% !important;
                       margin-top: 10px;
                }
            }
            @media(max-width: 1200px){  
                justify-content: center;
            }
        }
    }    
    .scroll-container {
        width: 100%;
        height: 204px;
        overflow: hidden;
        position: relative;
        border-radius: 8px;
        border: 1px solid #B3B3B3;
      
        .scroll-content {
            width: calc(100% - 10px); // Adjust for the left padding
            overflow-y: auto;
            padding-left: 10px; // Move content 10 pixels from the left border
            padding-right: 28px; // Adjust for scrollbar width and gap
            height: 190px;
            flex-shrink: 0;      
            margin: 5px 0 0 0;
          &::-webkit-scrollbar {
            width: 12px;
            height: 176px;
          }
      
          &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: #E73529;
          }
      
          &::-webkit-scrollbar-track {
            border-radius: 8px;
            background: #E6E7E8;
          }
        }
        .title{
            font-family: "roboto-bold";
            text-decoration: underline;
            font-weight: 700;
            text-align: center;
            margin-bottom: 20px !important;
        }
      }
    @media(max-width: 768px){
        .video{
            height: 278px;
        }
    }
    @media(max-width: 576px){
        .video-container{
            width: 100%;
            // height: 100%;
            display: flex;
            justify-content: center;
            .video{
                width: 351px;
                height: 181px;
            }
        }
        .scroll-content{
            font-size: 14px;
        }
    }
    @media(max-width: 1200px){
        .title-container{
            padding: 6px !important;
        }
    }
}