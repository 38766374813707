.module-class-container{
    align-self: stretch;
    height: 100%;
    padding: 0 !important;
    .module-class-banner-container{
        background-color: #FFF0E8;
        padding: 62px 0px;
        .module-class-banner-subcontainer{
            align-self: stretch;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .module-class-banner{
                display: flex;
                flex-direction: column;
                gap: 25px;
                .module-class-banner-title{
                    align-self: stretch;
                    color: #464646;
                    font-family: poppins-bold;
                    font-size: 64px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    @media(max-width: 768px){
                        font-size: 48px;
                    }
                }
                .module-class-banner-description{
                    align-self: stretch;
                    color: #464646;
                    font-family: roboto-bold;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%; 
                    @media(max-width: 768px){
                        font-size: 22px;
                    }
                }
                .module-class-banner-time{
                    display: flex;
                    align-items: flex-start;
                    gap: 16px;
                    .clock-img{
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        width: 20px;
                        height: 20px;
                    }
                    p{
                        color: #000;
                        font-family: roboto-light;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 150%;
                    }
                } 
            }
        }
        @media(max-width: 768px){
            padding: 50px 0px;
        }
        @media(max-width: 576px){
            padding: 30px 0px;
        }
    }

    .module-class-content-container{
        display: flex;
        justify-content: space-between;
        padding: 60px 0px;
        .module-class-component-container{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 39px;
            .buttons-container{
                display: flex;
                align-items: flex-start;
                gap: 16px;
                .btn-container{
                    display: flex;
                    padding: 10px 24px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 8px;
                    border: 1px solid #E73529;
                    background: #FFF;
                    .arrow-image-container{
                        background-position: center;
                        background-repeat: no-repeat;
                        width: 12px;
                        height: 8px;
                    }
                    .right{
                        background-image: url('./../../assets/screens/module-assets/red-right-arrow.svg');
                    }
                    .left{
                        background-image: url('./../../assets/screens/module-assets/red-left-arrow.svg');
                    }
                    .btn-text{
                        color: #E73529;
                        font-family: poppins-medium;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                        text-transform: uppercase;
                    }
                    &:hover{
                        cursor: pointer;
                        transition: ease-in-out 0.3s;
                        background-color: #E73529;
                        .btn-text{
                            color: #FFF;
                            transition: ease-in-out 0.3s;
                        }
                        .right{
                            background-image: url('./../../assets/screens/module-assets/white-right-arrow.svg');
                            transition: ease-in-out 0.3s;
                        }
                        .left{
                            background-image: url('./../../assets/screens/module-assets/white-left-arrow.svg');
                            transition: ease-in-out 0.1s;
                        }
                    }
                    &.disabled{
                        opacity: 0.5;
                        cursor: not-allowed;
                    }
                }
            }
        }
        .module-class-content-side-container{
            display: flex;
            padding: 24px;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 8px;
            border: 1px solid #D1D3D4;
            height: 100%;

            .module-class-content-title{
                align-self: stretch;
                color: #464646;
                font-family: roboto-bold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; 
                padding: 14px 0px 8px 0px !important;
                margin-bottom: 6px !important;
                border-bottom: 1px solid #D1D3D4;
            }

            .class-content-container{
                display: flex;
                padding: 12px 24px;
                align-items: flex-start;
                align-self: stretch;
                border-radius: 8px;
                background: #FFF;
                .class-content-link{
                    flex: 1 0 0;
                    color: #464646;
                    font-family: roboto-light;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%; 
                    text-decoration: none;
                    &.last-item {
                        color: #1C68DA;
                        text-decoration: none;
                  
                        &:hover {
                          text-decoration: underline;
                        }
                      }
                }
            }

            .active{
                background-color: #EEF5FF;
                .class-content-link{
                    color: #464646;
                    font-family: roboto-bold;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%; 
                }
            }
            @media (max-width: 992px) {
                display: none;
            }
        }
        @media(max-width: 768px){
            padding: 40px 0px;
        }
    }
    @media(max-width: 576px){
        width: 100%;
    }
}