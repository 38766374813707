.training-path-card-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .title-container{
        display: flex;
        align-items: center;
        gap: 16px;
        .training-path-title{
            color: #464646;
            font-family: roboto-regular;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; 
        }
        .training-path-link{
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        &:hover{
            .training-path-title{
                color:#1C68DA;
            }
        }
    }

    p{
        align-self: stretch;
        color: #464646;
        font-family: roboto-light;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;       
    }
}