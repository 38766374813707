.feedback-container{
    align-self: stretch;
    height: 100%;
    .feedback-banner-container{
        background-color: #FFF0E8;
        padding: 62px 0px;
        .feedback-banner-subcontainer{
            align-self: stretch;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .feedback-banner-left{
                display: flex;
                flex-direction: column;
                gap: 25px;
                .feedback-banner-title{
                    align-self: stretch;
                    color: #464646;
                    font-family: roboto-bold;
                    font-size: 64px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    @media(max-width: 992px){
                        font-size: 48px;
                    }
                    @media(max-width: 768px){
                        font-size: 38px;
                    }
                }
                .feedback-banner-description{
                    align-self: stretch;
                    color: #464646;
                    font-family: roboto-bold;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    @media(max-width: 992px){
                        font-size: 22px;
                    }
                    @media(max-width: 768px){
                        font-size: 22px;
                    }
                }
                .feedback-banner-time{
                    display: flex;
                    align-items: flex-start;
                    gap: 16px;
                    .clock-img{
                        background-image: url('./../../assets/screens/module-assets/clock1.svg');
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        width: 20px;
                        height: 20px;
                    }
                    p{
                        color: #000;
                        font-family: roboto-light;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 150%;
                    }
                }
                
            }
        }
    }
    .answer-container{
        padding: 0 !important;
        display: flex;
        align-self: stretch;
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
        .feedback-content{
            align-self: stretch;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 28px;
            .list{
                display: flex;
                align-self: stretch;
                flex-direction: column;
                align-items: flex-start;
                border: 1px solid #E02216;
                border-radius: 8px;
                .list-item {
                    display: flex;
                    align-self: stretch;
                    .list-paragraph-container {
                        display: flex;
                        padding: 18px;
                        align-items: center;
                        gap: 10px;
                        flex: 1 0 0;
                        border-right: 1px solid #E02216;
                        p{
                            color: #000;
                            font-family: roboto-light;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 150%; 
                        }
                        @media( max-width: 576px){
                            border-right: none;
                        }
                    }

                    
                    .check-container{
                        display: flex;
                        padding: 18px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        width: 194px;
                        align-self: stretch;
                        mix-blend-mode: darken;
                        border-left: none;

                        .radio-container{
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            align-self: stretch;
                            gap: 12px;
                            .option-label{
                                flex: 1 0 0;
                                color: #464646;
                                font-family: roboto-light;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: 150%; 
                            }
                            .input-checkbox-displayed{
                                display: flex;
                                width: 30px;
                                height: 30px;
                                padding: 10px;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                gap: 10px;
                                flex-shrink: 0;
                                border-radius: 8px;
                                border: 1px solid #D1D3D4;
                                &:hover{
                                    cursor: pointer;
                                    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
                                }
                                &.input-active{
                                    background-image: url('./../../assets/screens/test-assets/checked.svg');
                                    background-color: transparent;
                                    background-position: center;
                                    background-repeat: no-repeat;
                                    stroke-width: 3px;
                                    stroke: #99BF5F;
                                    border: 1px solid #99BF5F;
                                }
                            }
                        }
                        .error-msg{
                            color: red;
                            flex: 1 0 0;
                            font-family: roboto-bold;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 150%; 
                        }
                        @media( max-width: 576px){
                            padding-top: 0px ;
                        }
                    }
                    @media( max-width: 576px){
                        flex-direction: column;
                    }
                }

                #list-item-2{
                    border-top: 1px solid #E02216;
                }
            }
        }

        .buttons-container{
            display: flex;
            align-items: flex-start;
            gap: 16px;
            .btn-container{
                display: flex;
                padding: 10px 24px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 8px;
                border: 1px solid #E73529;
                background: #FFF;
                .arrow-image-container{
                    background-position: center;
                    background-repeat: no-repeat;
                    width: 12px;
                    height: 8px;
                }
                .right{
                    background-image: url('./../../assets/screens/module-assets/red-right-arrow.svg');
                }
                .left{
                    background-image: url('./../../assets/screens/module-assets/red-left-arrow.svg');
                }
                .btn-text{
                    color: #E73529;
                    font-family: poppins-medium;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    text-transform: uppercase;
                }
                &:hover{
                    cursor: pointer;
                    transition: ease-in-out 0.3s;
                    background-color: #E73529;
                    .btn-text{
                        color: #FFF;
                        transition: ease-in-out 0.3s;
                    }
                    .right{
                        background-image: url('./../../assets/screens/module-assets/white-right-arrow.svg');
                        transition: ease-in-out 0.3s;
                    }
                    .left{
                        background-image: url('./../../assets/screens/module-assets/white-left-arrow.svg');
                        transition: ease-in-out 0.1s;
                    }
                }
            }
        }
        @media(max-width: 576px){
            width: 80%;
        }
    }
}