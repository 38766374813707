.pershkrimifunksionimit-container{
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 40px;
    .content-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 18px;
        h3{
            color: #E73529;
            font-family: roboto-medium;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; 
        }
        .content-subcontainer{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            .content-paragraph-container{
                display: flex;
                flex-direction: column;
                align-self: stretch;
                gap: 24px;
                p{
                    color: #464646;
                    font-family: roboto-light;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%; 
                    span{
                        color: #E73529;
                        font-family: roboto-bold;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 150%; 
                    }
                    .black-span{
                        color: #464646;
                        font-family: roboto-bold;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 150%; 
                    }
                }
            }
            .content-list-container{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 18px;
                p{
                    color: #464646;
                    font-family: roboto-light;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%; 
                    span{
                        color: #E73529;
                        font-family: roboto-bold;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 150%; 
                    }
                }
                ul{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 24px;
                    li{
                        color: #464646;
                        font-family: roboto-light;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 150%; 
                        margin-left: 25px !important;  
                        span{
                            color: #E73529;
                            font-family: roboto-bold;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 150%; 
                        }
                    }
                }
            }

            .important-paragraph-container{
                display: flex;
                padding: 12px;
                justify-content: center;
                align-items: center;
                align-self: stretch;
                border-radius: 8px;
                border: 1px solid #B3B3B3;
                background: #FFF;
                box-shadow: -2px 4px 8px 0px rgba(0, 0, 0, 0.10);
                p{
                    color: #E02216;
                    font-family: roboto-light;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%; /* 21px */
                }
            }
        }
    }
    @media( max-width: 576px){
        .stop-reflect-container{
            align-self: stretch;
        }
        align-self: stretch;
    }
}