// $baraziaImageHeight: '480px';
// $baraziaImageWidth: '100%';

.psearsimi-container{
    display: flex;
    align-items: flex-start;
    gap: 18px;
    flex-wrap: wrap;
    .list-container{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 14px;
        .explaining-text{
            color: #464646;
            font-family: roboto-light;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%; 
        }
        .list{
            display: flex;
            flex-direction: column;
            gap: 33px;
            .list-item{
                display: flex;
                align-items: flex-start;
                .list-bullet{
                    margin: 7.5px 12px 7.5px 0px; 
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #E73529;
                }
                p{
                    flex: 1 0 0;
                    color: #464646;
                    font-family: roboto-light;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%; 
                    span{
                        color: #464646;
                        font-family: roboto-bold;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 150%;
                    }
                }
            }
        }
    }
    .burimet-direct{
        color: #464646;
        font-family: roboto-light;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; 
        a{
            color: #1C68DA;
            font-family: roboto-medium;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; 
        }
    }
    
    .barazia-image-container{
        height: 480px;
        width: 100%;
        .barazia-image{
            height: 100%;
            align-self: stretch;
            flex-shrink: 0;
            background-image: url('./../../../../../assets/screens/module-assets/barazia.jpg');
            background-repeat: no-repeat;
            background-size: cover;
        }
        @media(max-width: 1399px){
            height: 410px;
        }
        @media(max-width: 1200px){
            height: 343px;
        }
        @media(max-width: 992px){
            height: 386px;
        }
        @media(max-width: 767px){
            height: 289px;
        }
        @media(max-width: 575px){
            width: 100%;
            height: 297px;
            margin: auto;
        }
        @media(max-width: 559px){
            width: 350px;
            height: 198px;
            margin: auto;
        }
        @media(max-width: 400px){
            width: 320px;
            height: 180px;
        }
    }

    .science-studies-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 18px;
        p{
            color: #464646;
            font-family: roboto-light;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%; 
        }
        ul{
            display: flex;
            flex-direction: column;
            gap: 20px;
            li{
                color: #464646;
                font-family: roboto-light;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 150%;
                margin-left: 25px !important;  
                span{
                    color: #464646;
                    font-family: roboto-bold;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%;
                }
            }
        }
    }
}