.ligjetpolitikatperarsimin-container{
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    p{
        color: #464646;
        font-family: roboto-light;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; 
    }
    @media(max-width: 575px){
        .image-container{
            height: 100%;
            width: 100%;
        }
    }
    .image-subcontainer{
        background-image: url('./../../../../../assets/screens/module-assets/dancing.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 428px;
        @media(max-width: 1400px){
            height: 368px;
        }
        @media(max-width: 1200px){
            height: 308px;
        }
        @media(max-width: 992px){
            height: 418px;
        }
        @media(max-width: 768px){
            width: 516px;
            height: 310px;
        }
        @media(max-width: 575px){
            margin: auto;
            width: 351px;
            height: 211px;
        }
        @media(max-width: 400px) {
            height: 160px;
            width: 270px;
        }
    }
    .ligjet-container{
        display: flex;
        padding: 18px 12px;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;
        border-radius: 8px;
        background: #E8F3FF;
        box-shadow: -2px 4px 8px 0px rgba(0, 0, 0, 0.25);
        ul{
            display: flex;
            flex-direction: column;
            gap: 20px;
            li{
                color: #464646;
                font-family: roboto-light;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 150%;
                margin: 7.5px 12px 7.5px 25px !important;  
            }
        }
    }
}