.memory-game-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    h3{
        color: #464646;
        font-family: 'roboto-bold';
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; 
        @media( max-width: 575px){
            font-size: 24px;
        }
    }
    .memory-game-subcontainer {
        margin-top: 26px;
        display: flex;
        width: 464px;
        padding: 18px;
        flex-direction: column;
        align-items: flex-start;
        gap: 18px;
        border-radius: 8px;
        border: 1px solid #D1D3D4;
      
        h3 {
          color: #464646;
          font-family: 'roboto-bold';
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
      
          @media (max-width: 575px) {
            font-size: 28px;
          }
        }
        .memory-game{
            display: flex;
            flex-direction: column;
            align-self: stretch;
            gap: 28px;
        }
      
        @media (max-width: 575px) {
          width: 100% !important;
        }
      
        .buttons-container {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 18px;
          align-self: stretch;
      
          .btn-container {
            display: flex;
            padding: 10px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: #FFF;
      
            .btn-text {
              color: #E73529;
              font-family: 'poppins-medium';
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              text-transform: uppercase;
            }
      
            &:hover {
              cursor: pointer;
              transition: ease-in-out 0.3s;
              background: #E73529;
      
              .btn-text {
                color: #FFF;
              }
            }
            &.disabled{
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
        }
        @media( max-width: 575px){
            gap: 28px;
        }
    }
    .copyright{
        color: #B8B8B8;
        font-family: 'roboto-regular';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; 
    }
    @media( max-width: 575px){
        align-self: stretch;
    }
}