.login-container{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    .login-form-container{
        display: flex;
        width: 368px;
        padding: 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        border-radius: 8px;
        border: 1px solid #D1D3D4;
        background: #FFF;
        .title{
            align-self: stretch;
            color: #464646;
            text-align: center;
            font-family: poppins-bold;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; 
        }
        .description{
            align-self: stretch;
            color: #464646;
            text-align: center;
            font-family: roboto-regular;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; 
            .signup-link{
                color: #E73529;
                text-decoration: none;
                &:hover{
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
        .btn-container{
            display: flex;
            align-self: stretch;
            height: 40px;
            padding: 10px 24px;
            justify-content: center;
            align-items: center;
            gap: 18px;

            border-radius: 8px;
            border: 1px solid #D1D3D4;
            background: #E73529;

            &:hover{
                cursor: pointer;
            }
            
            color: #FFF;

            text-align: center;
            font-family: poppins-regular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }
}