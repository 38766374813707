.hyrjeinstrumentet-container{
    padding: 0 !important;
    display: flex;
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    p{
        color: #464646;
        font-family: roboto-light;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; 
    }
    .hyrjeinstrumentet-subcontainer{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        .paragraph-container{
            align-self: stretch;
        }
        .image-container{
            width: 378px;
            height: 289px;
            flex-shrink: 0;
            background-image: url('./../../../../../assets/screens/module-assets/vendimi-qeverise.svg');
            background-size: cover;
            background-repeat: no-repeat;
            @media(max-width: 768px){
                width: 300px;
                height: 230px;
                margin: auto;
            }
        }
    }
    @media(max-width: 768px){
        gap: 24px;
    }
}