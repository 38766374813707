.sinetzabiopsikosociale-container{
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    .main-content-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 18px;
        
        .content-paragraph-container{
            font-family: roboto-light;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%; /* 21px */
        }
        .content-list-container{
            display: flex;
            flex-direction: column;
            gap: 20px;
            ul{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                li{
                    color: #464646;
                    font-family: roboto-bold;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%; 
                    margin-left: 25px !important; 
                }
            }
            p{
                color:#E02216;
                font-family: roboto-light;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 150%; /* 21px */
            }
            span{
                color: #464646;
            }
        }
    }
    .underlist-content-container{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .important-paragraph-container{
            background-color: #FFF0E8;
        }
        h1{
            color: #464646;
            font-family: roboto-bold;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; 
        }
        p{
            color: #464646;
            font-family: roboto-light;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%;
        }
    }

    @media(max-width: 768px){
        .video{
            height: 278px;
        }
    }
    @media(max-width: 576px){
        .video-container{
            width: 100%;
            // height: 100%;
            display: flex;
            justify-content: center;
            .video{
                width: 351px;
                height: 181px;
            }
        }
        .scroll-content{
            font-size: 14px;
        }
    }
    
    .undervideo-content-container{
        display: flex;
        flex-direction: column;
        gap: 20px;
        
        p{
                color: #464646;
                font-family: roboto-light;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 150%; /* 21px */
        }
    }    
    .content-card-container{
        display: flex;
        padding: 18px 12px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        gap: 20px;
        border-radius: 8px;
        background: #FFF3E8;
        box-shadow: -2px 4px 8px 0px rgba(0, 0, 0, 0.25);
        p{
            align-self: stretch;
            color: #000;
            font-family: roboto-medium;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; 
        }
        .list{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 18px;
            align-self: stretch;
            .list-item{
                display: flex;
                align-items: flex-start;
                .list-bullet{
                    margin: 7.5px 12px 7.5px 0px; 
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #E73529;
                }
                p{
                    flex: 1 0 0;
                    color: #464646;
                    font-family: roboto-light;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%; 
                    span{
                        align-self: stretch;
                        color: #000;
                        font-family: roboto-medium;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 150%; 
                    }
                }
            }
        }
    }
    .bottom-paragraph-container{
        display: flex;
        flex-direction: column;
        gap: 20px;
        p{
            color: #464646;
            font-family: roboto-light;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%; /* 21px */
        }
    }
    .important-paragraph-container{
        display: flex;
        padding: 12px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        border: 2px solid #E02216;
        background: #FFF;
        box-shadow: -2px 4px 8px 0px rgba(0, 0, 0, 0.10);
        p{
            color: #E02216;
            font-family: roboto-bold;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
        }
    }
    .scroll-container {
        font-family: "roboto-regular";
        width: 100%;
        height: 204px;
        overflow: hidden;
        position: relative;
        border-radius: 8px;
        border: 1px solid #B3B3B3;
      
        .scroll-content {
            width: calc(100% - 10px); // Adjust for the left padding
            overflow-y: auto;
            padding-left: 10px; // Move content 10 pixels from the left border
            padding-right: 28px; // Adjust for scrollbar width and gap
            height: 190px;
            flex-shrink: 0;      
            margin: 5px 0 0 0;
            p{
                color: #464646;
                font-family: "roboto-light";
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 150%;
            }
            .title{
                font-family: "roboto-bold";
                text-decoration: underline;
                font-weight: 700;
                text-align: center;
                margin-bottom: 20px !important;
            }
            .blueText{
                color: #8eaadb;
            }
            .greenText{
                color: #a8d08d;
            }
          // Optional: Style the scrollbar
          &::-webkit-scrollbar {
            width: 12px;
            height: 176px;
          }
      
          &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: #E73529;
          }
      
          &::-webkit-scrollbar-track {
            border-radius: 8px;
            background: #E6E7E8;
          }
        }
        .title{
            font-family: "roboto-bold";
            text-decoration: underline;
            font-weight: 700;
            text-align: center;
            margin-bottom: 20px !important;
        }
        span{
            font-family: roboto-bold;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
        }
      }
}