.niveletarsimit-dropdown-container{
    width: 23.5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    .niveletarsimit-dropdown-subcontainer{
        display: flex;
        padding: 18px;
        align-self: stretch;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #D1D3D4;
        background: #FFF;
        p{
            color: #464646;
            font-family: 'roboto-bold';
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; 
        }
        .dropdown-icon{
            background-image: url('./../../../../../../assets/screens/module-assets/dropdownicon.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 15px;
            height: 9px;
            flex-shrink: 0;
        }
        .up-icon{
            background-image: url('./../../../../../../assets/screens/module-assets/dropdownicon.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 15px;
            height: 9px;
            flex-shrink: 0;
            transform: rotate(180deg);
            transition: ease-in-out 0.3s;
        }
        &:hover{
            cursor: pointer;
            background: #FFF;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
        }
    }
    .niveletarsimit-dropdown-option{
        display: flex;
        align-self: stretch;
        padding: 18px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        border-radius: 8px;
        border: 1px solid #D1D3D4;
        background: #EEF5FF;
        .niveletarsimit-dropdown-option-img{
            width: 121px;
            height: 121px;
            flex-shrink: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    @media(max-width: 768px){
        width: calc(50% - 4px);
    }
    @media(max-width: 575px){
        width: 100%;
    }
}