.ligjiarsimitparauniversitar-container{
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    p{
        color: #464646;
        font-family: roboto-light;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        span{
            color: #464646;
            font-family: roboto-bold;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
        }
        a{
            color: #1C68DA;
            font-family: roboto-medium;
        }
    }
    .neni-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 18px;
        .pikat-nenit-container{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            .pikat-nenit-title-container{
                .pikat-nenit-title,
                .pikat-nenit-desc{
                    color: #464646;
                    font-family: roboto-bold;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%; 
                }
            }
        }
    }

    .content-card-container{
        display: flex;
        align-self: stretch;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        border: 1px solid #B3B3B3;
        background: #FFF;
        box-shadow: -2px 4px 8px 0px rgba(0, 0, 0, 0.10);
    }

}