.instrumentetvleresuese-container{
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    p{
        color: #464646;
        font-family: roboto-light;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; 
    }
    .content-card-container{
        display: flex;
        padding: 18px 12px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        gap: 20px;
        border-radius: 8px;
        background: #EFE8FF;
        box-shadow: -2px 4px 8px 0px rgba(0, 0, 0, 0.25);
        p{
            align-self: stretch;
            color: #000;
            font-family: roboto-medium;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; 
        }
        .list{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 18px;
            align-self: stretch;
            .list-item{
                display: flex;
                align-items: flex-start;
                .list-bullet{
                    margin: 7.5px 12px 7.5px 0px; 
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #E73529;
                }
                p{
                    flex: 1 0 0;
                    color: #464646;
                    font-family: roboto-light;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%; 
                }
            }
        }
    }
}