.formulariraportimit-container{
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    h1{
        color: #464646;
        font-family: roboto-bold;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; 
    }
    p{
        color: #464646;
        font-family: roboto-light;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        span{
            color: #464646;
            font-family: roboto-bold;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
        }
        .red-light-span{
            color: #E02216;
            font-family: roboto-light;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%;
        }
        .red-bold-span{
            color: #E02216;
            font-family: roboto-bold;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
        }
    }
    .list-container{
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-self: stretch;
        .list-subcontainer{
            display: flex;
            flex-direction: column;
            align-self: stretch;
            gap: 20px;
            ul,ol{
                display: flex;
                flex-direction: column;
                gap: 20px;
                li{
                    color: #464646;
                    font-family: roboto-light;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%;
                    margin-left: 25px !important;  
                    
                }
            }
            ol{
                gap: 2px;
            }
        }
    }
    .kid-personal-info-img{
        // width: 350px;
        height: 479px;
        flex-shrink: 0;
        background-image: url('./../../../../../assets/screens/module-assets/personalinfo-kids.svg');
        background-repeat: no-repeat;
        background-size: contain;
        @media(max-width: 576px){
            width: 315px;
            height: 479px;
        }
        @media(max-width: 400px){
            height: 410px;
            width: 300px;
        }
    }

    .content-card-container{
        display: flex;
        padding: 18px 12px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        gap: 20px;
        border-radius: 8px;
        box-shadow: -2px 4px 8px 0px rgba(0, 0, 0, 0.25);
        p{
            align-self: stretch;
            color: #000;
            font-family: roboto-medium;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; 
        }
        .list{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 18px;
            align-self: stretch;
            .list-item{
                display: flex;
                align-items: flex-start;
                .list-bullet{
                    margin: 7.5px 12px 7.5px 0px; 
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #E73529;
                }
                p{
                    flex: 1 0 0;
                    color: #464646;
                    font-family: roboto-light;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%; 
                }
                
            }
        }
    }

    #content-card-container-lightorange{
        background-color: #FFF3E8;
    }

    #content-card-container-rose{
        background-color: #FFE8F0;
    }

    .pjesaraportimit-img{
        background-repeat: no-repeat;
        background-size: cover;
        width: 444px;
        height: 281px;
        flex-shrink: 0;
        @media(max-width: 576px){
            width: 351px;
            height: 222px;
        }
        @media( max-width: 400px){
            width: 100%;
            justify-content: center;
            height: 229px;
            width: 270px;
                // .image1{
                //     width: 260px !important;
                // }
            }
    }

    .image1{
        background-image: url('./../../../../../assets/screens/module-assets/pjesaraportimit-img1.svg');
    }

    .image2{
        background-image: url('./../../../../../assets/screens/module-assets/pjesaraportimit-img2.svg');
    }

    .image3{
        background-image: url('./../../../../../assets/screens/module-assets/pjesaraportimit-img3.svg');
    }

    .image4{
        background-image: url('./../../../../../assets/screens/module-assets/pjesaraportimit-img4.svg');
    }

    .image5{
        background-image: url('./../../../../../assets/screens/module-assets/pjesaraportimit-img5.svg');
    }

    .image6{
        background-image: url('./../../../../../assets/screens/module-assets/pjesaraportimit-img6.svg');
        width: 291px;
        height: 270px;
    }

    .lists-container{
        display: flex;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;
        .paragraph-list-container{
            display: flex;
            flex-direction: column;
        }
    }

    .paragraphcard-container{
        display: flex;
        align-self: stretch;
        flex-direction: column;
        align-items: flex-start;
        gap: 18px;
    }

    .content {
        align-self: stretch;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 28px;

        .list {
            display: flex;
            align-self: stretch;
            flex-direction: column;
            align-items: flex-start;

            .list-item {
                display: flex;
                align-self: stretch;
                border: 1px solid #D1D3D4;
                border-bottom: none;
                &:last-child {
                    border-bottom: 1px solid #D1D3D4;
                }
                .list-paragraph-container {
                    display: flex;
                    padding: 18px;
                    justify-content: center;
                    gap: 10px;
                    border-right: 1px solid #D1D3D4;
                    mix-blend-mode: darken;
                    flex: 1 0 0;
                    .list-paragraph-number {
                        display: flex;
                        height: 100%;
                        align-items: flex-start;

                        .number-of-paragraph {
                            color: #000;
                            font-family: roboto-light;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 150%;
                        }
                    }

                    .content-paragraph-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex: 1 0 0;
                        height: 100%;

                        .content-of-paragraph {
                            flex: 1 0 0;
                            color: #000;
                            font-family: roboto-light;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 150%;

                            span {
                                color: red;
                                font-family: roboto-bold;
                                font-weight: 700;
                            }
                        }
                    }

                    .content-of-paragraph {
                        flex: 1 0 0;
                        color: #000;
                        font-family: roboto-light;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 150%;

                        span {
                            color: red;
                            font-family: roboto-bold;
                            font-weight: 700;
                        }
                    }
                }

                .check-container,
                .stop-container {
                    display: flex;
                    padding: 18px;
                    width: 97px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    align-self: stretch;
                    mix-blend-mode: darken;
                }

                .check-container{
                    border-right: 1px solid #D1D3D4;
                    &:hover{
                        background-color: #99BF5F;
                        transition: ease-in-out 0.3s;
                        cursor: pointer;
                        .check-icon{
                            transition: ease-in-out 0.3s;
                            background-image: url('./../../../../../assets/screens/module-assets/white-checked.svg');
                        }
                    }
                    &.active{
                        background-color: #99BF5F;
                        transition: ease-in-out 0.3s;
                        cursor: pointer;
                        .check-icon{
                            transition: ease-in-out 0.3s;
                            background-image: url('./../../../../../assets/screens/module-assets/white-checked.svg');
                        }
                    }
                }

                .stop-container{
                    &:hover{
                        transition: ease-in-out 0.3s;
                        background-color: #E02216;
                        cursor: pointer;
                        .stop-icon{
                            transition: ease-in-out 0.3s;
                            background-image: url('./../../../../../assets/screens/module-assets/white-stop.svg');
                        }
                    }

                    &.active{
                        transition: ease-in-out 0.3s;
                        background-color: #E02216;
                        cursor: pointer;
                        .stop-icon{
                            transition: ease-in-out 0.3s;
                            background-image: url('./../../../../../assets/screens/module-assets/white-stop.svg');
                        }
                    }
                }

                .icona {
                    width: 33px;
                    height: 33px;
                    background-size: cover;
                    background-repeat: no-repeat;
                }

                .check-icon {
                    background-image: url('./../../../../../assets/screens/module-assets/checked-icon.svg');
                }

                .stop-icon {
                    background-image: url('./../../../../../assets/screens/module-assets/stop-icon.svg');
                }

                @media(max-width: 576px){
                    flex-wrap: wrap;
                    .list-paragraph-container{
                        width: 100%;
                        border-right: none;
                        border-left: none;
                    }
                    .check-container,.stop-container{
                        width: 100%;
                        border-top: 1px solid #D1D3D4;
                        border-right: none;
                        border-left: none;
                    }
                }
            }
        }
        .buttons-container-mod {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 18px;
            align-self: stretch;
    
            .btn-container-mod {
                display: flex;
                padding: 10px 24px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 8px;
                background: #1C68DA;
                border: none;
                outline: none;
                .btn-text-mod {
                    color: #fff;
                    font-family: 'poppins-medium';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    text-transform: uppercase;
                }
    
                &:hover {
                    cursor: pointer;
                    background: #E73529;
                    transition: ease-in-out 0.3s;
                }
                &.disabled{
                    opacity: 0.5;
                    cursor: not-allowed;
                }
            }
        }
    }
    .scroll-container {
        font-family: "roboto-regular";
        width: 100%;
        height: 204px;
        overflow: hidden;
        position: relative;
        border-radius: 8px;
        border: 1px solid #B3B3B3;
      
        .scroll-content {
            width: calc(100% - 10px); // Adjust for the left padding
            overflow-y: auto;
            padding-left: 10px; // Move content 10 pixels from the left border
            padding-right: 28px; // Adjust for scrollbar width and gap
            height: 190px;
            flex-shrink: 0;      
            margin: 5px 0 0 0;
            .blueText{
                color: #8eaadb;
            }
            .greenText{
                color: #a8d08d;
            }
          // Optional: Style the scrollbar
          &::-webkit-scrollbar {
            width: 12px;
            height: 176px;
          }
      
          &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: #E73529;
          }
      
          &::-webkit-scrollbar-track {
            border-radius: 8px;
            background: #E6E7E8;
          }
        }
        .title{
            font-family: "roboto-bold";
            text-decoration: underline;
            font-weight: 700;
            text-align: center;
            margin-bottom: 20px !important;
        }
        span{
            font-family: 'roboto-bold';
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
        }
        h5{
            font-family: 'roboto-bold';
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
        }
      }
    @media(max-width: 768px){
        .video{
            height: 278px;
        }
    }
    @media(max-width: 576px){
        .video-container{
            width: 100%;
            display: flex;
            justify-content: center;
            .video{
                width: 351px;
                height: 181px;
            }
        }
    }
}