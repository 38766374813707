@media(max-width: 768px){
    .video{
        height: 278px;
    }
}
@media(max-width: 576px){
    .video-container{
        width: 100%;
        display: flex;
        justify-content: center;
        .video{
            width: 351px;
            height: 181px;
        }
    }
}