.myprofile-container{
    align-self: stretch;
    height: 100%;
    padding: 0 !important;
    .myprofile-banner-container{
        background: #EEF5FF;
        padding: 33px 0px;
        .myprofile-banner-subcontainer{
            align-self: stretch;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .myprofile-banner-title{
                color: #464646;
                font-family: 'roboto-bold';
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
            }
        }
        @media(max-width: 768px){
            padding: 25px 0px;
        }
        @media(max-width: 576px){
            padding: 23px 0px;
        }
    }

    .myprofile-content-container{
        display: flex;
        padding: 60px 0px;
        .myprofile-info-container{
            display: flex;
            gap: 50px;
            padding: 18px 12px;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid #D1D3D4;
            background: #FFF;
            position: sticky;
            top: 20px;
            left: 0;
            height: 100%;
            .image-info-container{
                display: flex;
                justify-content: flex-start;
                gap: 18px;
                align-self: stretch;
                .image-container{
                    width: 108px;
                    height: 108px;
                    border: 1px solid #D1D3D4;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #EEF5FF;
                    p{
                        color:#464646;
                        font-family: 'roboto-bold';
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                    }
                }
                .btn-container {
                    display: flex;
                    padding: 10px 24px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    flex: 1 0 0;
                    border-radius: 8px;
                    border: 1px solid #e73529;
                    background: #fff;
                  
                    .btn-text {
                      flex: 1 0 0;
                      color: #e73529;
                      text-align: center;
                      font-family: 'poppins-medium';
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 20px;
                      text-transform: uppercase;
                    }
                  
                    &:hover {
                      transition: ease-in-out 0.3s;
                      cursor: pointer;
                      background: #e73529;
                  
                      .btn-text {
                        color: #fff;
                        transition: ease-in-out 0.3s;
                      }
                    }
                  
                    &.disabled {
                    //   opacity: 0.5;
                        background-color: #1C68DA;
                      cursor: not-allowed;
                    }
                  }
            }
            .infot-container{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
                .emri-container{
                    display: flex;
                    align-items: flex-start;
                    gap: 18px;
                    align-self: stretch;
                    .emri-subcontainer{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 8px;
                        .emri-top{
                            color: #464646;
                            font-family: 'roboto-medium';
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 150%;
                        }
                        .emri-bottom{
                            color: #464646;
                            font-family: 'roboto-regular';
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 150%;
                        }
                        .input-lg-container{
                            display: flex;
                            padding: 5px 10px;
                            align-items: center;
                            gap: 18px;
                            align-self: stretch;
                            border-radius: 8px;
                            border: 1px solid #D1D3D4;
                            background: #FFF;
                            input{
                                border: none;
                                outline: none;
                            }
                        }
                    }
                    .edit-container{
                        cursor: pointer;
                    }
                }
                .btn-container {
                    display: flex;
                    padding: 10px 24px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border: 1px solid #E73529;
                    border-radius: 8px;
                    background: #FFF;
              
                    .btn-text {
                      color: #E73529;
                      font-family: poppins-medium;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 20px;
                      text-transform: uppercase;
                    }
              
                    &:hover {
                      cursor: pointer;
                      transition: ease-in-out 0.3s;
                      background: #E73529;
              
                      .btn-text {
                        color: #FFF;
                      }
                    }
                  }
            }
            @media(max-width: 768px){
                position: static;
                margin-bottom: 20px;
            }
        }
        @media(max-width: 576px){
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
    }

    .courses-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 18px;
        h2{
            color: #464646;
            font-family: 'roboto-bold';
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
        }
        .course-container{
            display: flex;
            padding: 18px 12px;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid #D1D3D4;
            background: #FFF;
            height: 100%;
            .course-name-container{
                display: flex;
                align-items: flex-start;
                gap: 32px;
                align-self: stretch;
                h1{
                    flex: 1 0 0;
                    color: #464646;
                    font-family: 'roboto-bold';
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                }
            }
            .module-container{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 18px;
                .module-title{
                    color: #464646;
                    font-family: 'roboto-bold';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%;
                }
                .module-result{
                    color: #99BF5F;
                    font-family: 'poppins-bold';
                    font-size: 64px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%; 
                }
                .module-description{
                    color: #464646;
                    font-family: 'roboto-light';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%;
                    span{
                        font-family: 'roboto-medium';
                        font-weight: 500;
                        color: #1C68DA;
                        cursor: pointer;
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    @media(max-width: 576px){
        width: 100%;
    }
}