.ndalo-reflekto-container{
    display: flex;
    padding: 18px;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #D1D3D4;
    background: #F5F5F5;
    .ndalo-reflekto-title-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        h4{
            color: #464646;
            font-family: roboto-medium;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
        }
        p{
            align-self: stretch;
            color: #464646;
            font-family: roboto-light;
            font-size: 14px;
            font-style: italic;
            font-weight: 300;
            line-height: 150%;
        }
    }

    .input-container{
        display: flex;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid #D1D3D4;
        background: #FFF;

        input{
            flex: 1 0 0;
            border: none;
            outline: none;
        }

        input::placeholder{
            color: #D1D3D4;
            font-family: roboto-light;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
        }
        &:hover{
            border: 1px solid #1C68DA;
            transition: ease-in-out 0.3s;
        }
    }

    .buttons-container{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 18px;
        align-self: stretch;
        .btn-container{
            display: flex;
            padding: 10px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border: none;
            border-radius: 8px;
            background: #E73529;
            .btn-text{
                color: #FFF;
                font-family: poppins-medium;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 142.857% */
                text-transform: uppercase;
            }
            &:hover{
                cursor: pointer;
                transition: ease-in-out 0.3s;
                background: #1C68DA;
            }
        }
    }
    @media( max-width: 768px){
        padding: 18px 12px;
        gap: 10px;
        .ndalo-reflekto-title-container{
            align-items: center;
            gap: 4px;
            h4{
                font-size: 16px;
            }
            p{
                font-size: 12px;
                text-align: center;
            }
        }
        .input-container{
            padding: 8px;
            gap: 8px;
            input{
                font-size: 12px;
            }

            input::placeholder{
                font-size: 10px;

            }
        }
        .buttons-container{
            gap: 12px;
            justify-content: center;
        }
    }
    @media( max-width: 576px){
        align-self: stretch;
        width: 100%;
        .ndaloreflekto-container{
            width: 100%;
        }
    }
}