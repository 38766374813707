.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  
    .loader {
        border: 4px solid #E73529;
        border-top: 4px solid #f3f3f3;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        animation: spin 1s linear infinite;
        background-color: #fff; 
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }