.card-container{
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    height: 100%;
    .card-title{
        align-self: stretch;
        font-family: poppins-bold;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        &.done{
            color: #464646;
            &:hover{
                color: #FFF;
                text-decoration: underline;
            }
        }
        &.not-done{
            color: #FFF;
        }
    }

    .card-description{
        flex: 1;
        font-family: roboto-light;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; 
        margin: 0 !important;
        &.done{
            color: #464646;
            &:hover{
                color: #FFF;
            }
        }
        &.not-done{
            color: #FFF;
        }
    }

    .card-bottom{
        display: flex;
        align-self: stretch;
        justify-content: space-between;
        .card-btn{
            display: flex;
            padding: 10px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            border: 1px solid #FFF;
            background-color: #FFF;
            .card-btn-text{
                font-family: poppins-medium;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                text-transform: uppercase;
                &.done{
                   color: #D1D3D4;
                }
                &.not-done{
                    color: #E73529;
                }
            }
            .check-container{
                width: 16px;
                height: 16px;
            }
            &.done{
               border: 1px solid #D1D3D4;
            }
            &.not-done{
                color: #FFF;
                &:hover{
                    cursor: pointer;
                    border-radius: 8px;
                    transition: ease-in-out 0.3s;
                    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.25);
                    .card-btn-text{
                        color: #1C68DA;
                        transition: ease-in-out 0.3s;
                    }
                }
            }
        }

        .card-progress{
            display: flex;
            width: 40px;
            padding: 10px 20px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 29px;
            p{
                font-family: poppins-medium;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                text-transform: uppercase;
            }
            &.done{
                border: 1px solid #D1D3D4;
                p{
                    color: #D1D3D4;
                }
            }
            &.not-done{
                border: 1px solid #FFF;
                p{
                    color: #FFF;
                }
            }
        }
    }

    .card-title,.card-description{
        margin: 0;
    }

    &.done{
        background-color: #FFF;
        border: 1px solid #D1D3D4;
        &:hover{
            background: #D1D3D4;
            box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.25);       
            .card-title{
                color: #FFF;
                text-decoration: underline;
            }
            .card-description{
                color: #FFF;
            }
            .card-btn{
                cursor: pointer;
                .card-btn-text{
                    color: #99BF5F;
                }
            }
            .card-progress{
                border: 1px solid #FFF;
                p{
                    color: #FFF;
                }
            }
        }
    }
    
    &.not-done{
        background-color: #E73529;
        border: none;
        &:hover{
            background: radial-gradient(229.36% 140.44% at 26.09% 50.21%, #E73529 21.55%, #9747FF 80.51%);
            box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.25);        
        }
    }
}
