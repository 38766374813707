.rrethtrajnimit-container {
  position: relative;
  align-self: stretch;
  height: 100%;
  .banner-container {
    background-color: #fff0e8;
    padding: 62px 0px;
    .banner-subcontainer {
      align-self: stretch;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .banner-left {
        display: flex;
        flex-direction: column;
        gap: 25px;
        .banner-title {
          align-self: stretch;
          color: #464646;
          font-family: roboto-medium;
          font-size: 28px !important;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
        }
        .banner-subtitle {
          align-self: stretch;
          color: #464646;
          font-family: roboto-light;
          font-size: 20px;
          font-weight: bold;
          line-height: 150%;
        }
        .banner-description {
          align-self: stretch;
          color: #464646;
          font-family: roboto-light;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 150%;
        }
        .banner-register-btn-container {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          .banner-register-btn {
            display: flex;
            padding: 10px 24px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background-color: #e73529;
            p {
              color: #fff;
              font-family: poppins-regular;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              text-transform: uppercase;
            }
            @media (max-width: 992px) {
              p {
                font-size: 12px;
              }
            }
          }
          :hover {
            cursor: pointer;
            background-color: #1c68da;
            transition: ease-in-out 0.3s;
          }
          @media (max-width: 768px) {
            justify-content: center;
            p {
              font-size: 11px;
            }
          }
        }
        @media (max-width: 992px) {
          gap: 18px;
          .banner-title {
            font-size: 22px;
          }
          .banner-register-btn {
            width: 100px;
            padding: 10px 12px;
            p {
              font-size: 12px;
            }
          }
        }
        @media (max-width: 767px) {
          align-items: center;
          .banner-title {
            text-align: center;
          }
          .banner-description {
            text-align: center;
          }
        }
        @media (max-width: 576px) {
          align-items: center;
          .banner-title {
            text-align: center;
            font-size: 22px;
          }
          .banner-description {
            text-align: center;
          }
        }
      }
      .banner-right {
        display: flex;
        justify-content: flex-end;
        align-self: stretch;
        align-items: center;
        .banner-image-container {
          background-image: url('./../../assets/screens/home-assets/home-banner.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          height: 436px;
          width: 562px;
          flex-shrink: 0;
          @media (max-width: 1200px) {
            height: 376px;
            width: 482px;
          }
          @media (max-width: 992px) {
            height: 287px;
            width: 370px;
          }
        }
        @media (max-width: 767px) {
          .banner-image-container {
            height: 287px;
            width: 370px;
          }
        }
        @media (max-width: 576px) {
          width: 100%;
          justify-content: center;
          .banner-image-container {
            height: 287px;
            width: 370px;
          }
        }
      }
      @media (max-width: 767px) {
        flex-direction: column;
        gap: 20px;
      }
    }
    @media (max-width: 576px) {
      padding: 40px 0px;
    }
  }

  .training-menu-container {
    display: flex;
    align-self: stretch;
    .title-container {
      display: flex;
      padding: 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px 0px 0px 8px;
      background: #e73529;
      .title {
        color: #fff;
        font-family: roboto-bold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
      }
    }
    .description-container {
      display: flex;
      padding: 14.5px 24px;
      justify-content: space-between;
      align-items: center;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 0px 8px 8px 0px;
      background: #f5f5f5;
      .description {
        flex: 1 0 0;
        color: #464646;
        font-family: roboto-regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
      }
    }
    @media (max-width: 992px) {
      .title-container {
        padding: 12px;
        .title {
          font-size: 14px;
        }
      }
      .description-container {
        padding: 10px 12px;
        .description {
          font-size: 12px;
        }
      }
    }
    @media (max-width: 768px) {
      margin: auto;
      flex-direction: column;
      .title-container {
        padding: 18px;
        border-radius: 8px 8px 0px 0px;
        .title {
          font-size: 16px;
        }
      }
      .description-container {
        padding: 18px;
        border-radius: 0px 0px 8px 8px;
        .description {
          font-size: 14px;
        }
      }
    }
    @media (max-width: 576px) {
      width: 100%;
      .title-container {
        padding: 14px;
        .title {
          font-size: 14px;
        }
      }
      .description-container {
        padding: 10px 12px;
        .description {
          font-size: 12px;
        }
      }
    }
  }

  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: 992px) {
      .card-cont {
        margin-top: 20px;
      }
    }

    @media (max-width: 576px) {
      .card-cont {
        width: 100% !important;
      }
    }
  }

  .training-path-subcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 12px;
    .training-path {
      display: flex;
      padding: 18px 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid #e02216;
      background: #fff;
      .title {
        color: #464646;
        font-family: roboto-bold;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
      }
      @media (max-width: 1200px) {
        gap: 16px;
        .training-path-card-container {
          gap: 5px;
        }
      }
    }
    @media (max-width: 1200px) {
      align-self: stretch;
      justify-content: space-between;
    }
  }

  @media (max-width: 767px) {
    .training-path-container {
      display: flex;
      align-items: center;
      justify-content: center;
      .training-path-subcontainer {
        flex-direction: column;
        align-items: center;
        gap: 30px;
        .training-path {
          margin-left: 45px;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .training-path-container {
        width: 100%;
        .training-path-subcontainer {
          padding: 50px 0px;
          width: 100%;
          .training-path {
            margin: 0px 10px;
            .title {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}
