.footer-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px 0px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    .title{
        flex: 1 0 0;
        text-align: center;
        color: #B8B8B8;
        font-family: poppins-medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;  
    }
}