.demtimetaftesiakufizuar-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    padding: 0 !important;
    .question-1{
        color: #464646;
        font-family: roboto-medium;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; 
    }
    .answer{
        color: #464646;
        font-family: roboto-light;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; 
    }
    .answer-span{
        color: #464646;
        font-family: roboto-bold;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; 
    }

    .cards-title{
        color: #464646;
        font-family: roboto-medium;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; 
    }

    .demtimet-cards-container{
        display: flex;
        align-items: flex-start;
        gap: 20px;
        justify-content: center;
        flex-wrap: wrap;
        align-self: stretch;
        .demtimet-card-container{
            display: flex;
            width: 31%;
            height: 200px;
            padding: 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            background: #FFF0E8;
            box-shadow: -2px 4px 8px 0px rgba(0, 0, 0, 0.25);
            .card-answer{
                color: #464646;
                text-align: center;
                font-family: roboto-medium;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; 
            }
            @media(max-width:992px){
                width: 48%;
            }
            @media(max-width: 768px){
                width: 80%;
            }
        }
    }
}