.hyrjetest-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    .card-container{
        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid #D1D3D4;
        background: #E73529;
        height: 233px;
        h3{
            align-self: stretch;
            color: #FFF;
            font-family: poppins-bold;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-decoration-line: underline;
        }
    
        p{
            flex: 1;
            color: #FFF;
            font-family: roboto-light;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%; 
            margin: 0 !important;
        }
    
        .card-bottom{
            display: flex;
            align-self: stretch;
            justify-content: space-between;
            .card-btn{
                display: flex;
                padding: 10px 24px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 8px;
                border: 1px solid #FFF;
                background-color: #FFF;
                .card-btn-text{
                    color: #FFF;
                    font-family: poppins-medium;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    text-transform: uppercase;
                    color: #E73529;
                }
                &:hover{
                    cursor: pointer;
                    border-radius: 8px;
                    transition: ease-in-out 0.3s;
                    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.25);
                    .card-btn-text{
                        color: #1C68DA;
                        transition: ease-in-out 0.3s;
                    }
                }
            }
    
            .card-progress{
                display: flex;
                width: 40px;
                padding: 10px 20px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 29px;
                border: 1px solid #D1D3D4;
                p{
                    color: #FFF;
                    font-family: poppins-medium;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    text-transform: uppercase;
                }
            }
        }
    
    
        &:hover{
            background: radial-gradient(229.36% 140.44% at 26.09% 50.21%, #E73529 21.55%, #9747FF 80.51%);
            // background: radial-gradient(190% 135.44% at 15% 40.21%, #E73529 33.55%, #9747FF 75.51%);
            box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.25);        
        }
    
        h3,p{
            margin: 0;
        }
    }      
    .buttons-container{
        display: flex;
        align-items: flex-start;
        gap: 16px;
        .btn-container{
            display: flex;
            padding: 10px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            border: 1px solid #E73529;
            background: #FFF;
            .arrow-image-container{
                background-position: center;
                background-repeat: no-repeat;
                width: 12px;
                height: 8px;
            }
            .right{
                background-image: url('./../../../../assets/screens/module-assets/red-right-arrow.svg');
            }
            .left{
                background-image: url('./../../../../assets/screens/module-assets/red-left-arrow.svg');
            }
            .btn-text{
                color: #E73529;
                font-family: poppins-medium;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                text-transform: uppercase;
            }
            &:hover{
                cursor: pointer;
                transition: ease-in-out 0.3s;
                background-color: #E73529;
                .btn-text{
                    color: #FFF;
                    transition: ease-in-out 0.3s;
                }
                .right{
                    background-image: url('./../../../../assets/screens/module-assets/white-right-arrow.svg');
                    transition: ease-in-out 0.3s;
                }
                .left{
                    background-image: url('./../../../../assets/screens/module-assets/white-left-arrow.svg');
                    transition: ease-in-out 0.1s;
                }
            }
        }
    }
    @media( max-width: 768px ){
        #second-card{
            margin-top: 20px !important;    
            margin: auto;
        }
    }

    @media( max-width: 576px ){
        .hyrjetest-card-conatainer{
            margin-top: 20px !important;
            width: 90%; 
            margin: auto;
        }
    }

}