.question-container{
    display: flex;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 18px;
    .img-container {
        width: 160px;
        height: 160px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @media (max-width: 575px) {
          width: 140px;
          height: 140px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }
    }

    .input-form-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        .input-container{
            display: flex;
            gap: 5px;
            .input-checkbox-displayed{
                display: flex;
                width: 30px;
                height: 30px;
                padding: 10px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
                flex-shrink: 0;
                border-radius: 8px;
                border: 1px solid #D1D3D4;
                &:hover{
                    cursor: pointer;
                    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
                }
                &.input-active{
                    background-image: url('./../../../../../../assets/screens/test-assets/checked.svg');
                    background-color: transparent;
                    background-position: center;
                    background-repeat: no-repeat;
                    stroke-width: 3px;
                    stroke: #99BF5F;
                    border: 1px solid #99BF5F;
                }
                @media (max-width: 575px) {
                    height: 25px;
                    width: 25px;
                    } 
            }
        }
        input[type="radio"] {
            margin-right: 8px;
            display: none;
        }
    }
}