.testexplain-container{
    padding: 0 !important;
    .explanation-test-fill-container{
        display: flex;
        align-self: stretch;
        padding: 18px 12px;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        border-radius: 8px;
        border: 1px solid #E02216;
        background: #FFF;
        h3{
            color: #464646;
            font-family: roboto-medium;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            text-decoration-line: underline;
        }
        .list{
            display: flex;
            flex-direction: column;
            gap: 18px;
            .list-item{
                display: flex;
                align-items: flex-start;
                .list-bullet{
                    margin: 7.5px 12px 7.5px 0px; 
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #E73529;
                }
                p{
                    flex: 1 0 0;
                    color: #464646;
                    font-family: roboto-light;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%; 
                }
            }
        }
    }
    .buttons-container{
        display: flex;
        align-items: flex-start;
        gap: 16px;
        .btn-container{
            display: flex;
            padding: 10px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            border: 1px solid #E73529;
            background: #FFF;
            .arrow-image-container{
                background-position: center;
                background-repeat: no-repeat;
                width: 12px;
                height: 8px;
            }
            .right{
            background-image: url('./../../../../assets/screens/module-assets/red-right-arrow.svg');
            }
            .left{
            background-image: url('./../../../../assets/screens/module-assets/red-left-arrow.svg');
            }
            .btn-text{
                color: #E73529;
                font-family: poppins-medium;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                text-transform: uppercase;
            }
            &:hover{
                cursor: pointer;
                transition: ease-in-out 0.3s;
                background-color: #E73529;
                .btn-text{
                    color: #FFF;
                    transition: ease-in-out 0.3s;
                }
                .right{
                background-image: url('./../../../../assets/screens/module-assets/white-right-arrow.svg');
                    transition: ease-in-out 0.3s;
                }
                .left{
                background-image: url('./../../../../assets/screens/module-assets/white-left-arrow.svg');
                    transition: ease-in-out 0.1s;
                }
            }
        }
    }
}