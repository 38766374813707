.nav-switch{
    width: 100%;
    height: 100%;
    .navbar-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 44px;
        .navbar-left{
            .navbar-logo{
                width: 170px;
                height: 20px;
                cursor: pointer;
                .logo-img{
                    width: 100%;
                    height: 100%;
                    background-image: url('./../../../assets/shared/navbar-assets/logo.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
        }
        .nav-right{
            display: flex;
            align-items: center;
            .nav-links{
                display: flex;
                .nav-link{
                    display: flex;
                    padding: 12px 22px !important;
                    justify-content: center;
                    align-items: center;
                    color: #464646;
                    font-family: roboto-regular;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; 
                    &:hover{
                        padding-bottom: 10px !important;
                        border-bottom: 2px solid #464646;
                        cursor: pointer;
                    }
                    &:focus{
                        padding-bottom: 10px !important;
                        border-bottom: 2px solid #E73529;
                    }
                }
                .register-link{
                    color: #DA291C;
                }
            }
            .user-icon{
                background-image: url('./../../../assets/shared/navbar-assets/user-icon.svg');
                background-repeat: no-repeat;
                background-position: center;
                width: 29px;
                height: 29px;
                &:hover{
                    cursor: pointer;
                }
            }
            @media(max-width: 768px){
                display: none;
            }
        }
        .hamburger-menu{
            width: 30px;
            height: 30px;
            display: none;
            @media(max-width: 768px){
                display: flex;
                flex-direction: column;
                gap: 5px;
                justify-content: center;
                .hr{
                    width: 100%;
                    height: 3px;
                    background-color: #000;
                }
            }
        }
    }
    .responsive-nav-container{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        z-index: 5000;
        display: flex;
        padding: 0 12px 24px; 
        flex-direction: column;
        justify-content: space-between;
        .responsive-nav-head{
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 44px;
          .responsive-nav-logo{
            width: 170px;
            height: 20px;
            .logo-img{
                width: 100%;
                height: 100%;
                background-image: url('./../../../assets/shared/navbar-assets/logo.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
          }
          .responsive-nav-hamburgermenu{
            display: block;
            &:hover{
              cursor: pointer;
              transition: ease-in-out 200ms;
              transform: scale(0.9);
            }
          }
        }
        .responsive-nav-links{
          display: flex;
          flex-direction: column;
          gap: 15px;
          .responsive-nav-link{
            .link{
              cursor: pointer;
              text-decoration: none;
              p{
                color: #464646;
                font-family: roboto-regular;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; 
                text-align: center;
                &:hover{
                    color: #E73529;
                }
                &:focus{
                    color: #E73529;
                }
              }
            }
          }
        }
        .responsive-nav-auth{
            width: 100%;
            .responsive-nav-buttons{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                .btn{
                    display: flex;
                    padding: 10px 24px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 8px;
                }
                .login{
                    border: 1px solid #000;
                    p{
                        color: #000;
                        font-family: poppins-regular;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px; 
                        text-transform: uppercase;
                    }
                    &:active{
                    background-color: #E73529;
                    border: none;
                    color: white;
                    }
                }
                .signup{
                background-color: #E73529;
                p{
                    color: #FFF;
                    font-family: poppins-regular;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; 
                    text-transform: uppercase;
                }
                &:active{
                    background-color: transparent;
                    text-decoration: underline;
                }
                }
            }
        }
    }
    
}
