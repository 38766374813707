.burimet-container{
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    // flex: 1;
    .burimet-cards-container{
        align-self: stretch;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        align-items: center;
        flex: 1;
        .burimet-card-container{
            width: calc(50% - 10px);
            height: 180px;
            display: flex;
            padding: 16px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            border-radius: 8px;
            border: 1px solid #D1D3D4;
            background-color: #FFF;
            .burimet-card-title{
                color: #464646;
                font-family: roboto-bold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; 
                align-self: stretch;
            }
            .btn-container{
                display: flex;
                padding: 10px 24px;
                justify-content: center;
                align-items: center;
                gap: 16px;
                border-radius: 8px;
                border: 1px solid #E73529;
                background: #FFF;
                .btn-text{
                    color: #E73529;
                    font-family: poppins-medium;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; 
                    text-transform: uppercase;
                }
                .arrow-icon-container{
                    background-image: url('./../../../assets/screens/module-assets/red-down-arrow.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    width: 12px;
                    height: 12px;
                }
                &:hover{
                    transition: 0.3s ease-in;
                    cursor: pointer;
                    background: #E73529;
                    .btn-text{
                        transition: 0.3s ease-in;
                        color: #FFF;
                    }
                    .arrow-icon-container{
                        transition: 0.3s ease-in;
                        background-image: url('./../../../assets/screens/module-assets/white-down-arrow.svg');
                    }
                }
            }
            @media(max-width: 768px){
                height: 200px;
                .burimet-card-title{
                    font-size: 14px;
                }
            }
            @media(max-width: 576px){
                .btn-container{
                    padding: 8px 18px;
                    gap: 8px;
                    width: 100%;
                }
            }
        }
    }
}