.test-container {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;

    .test-form {
        display: flex;
        flex-direction: column;
        gap: 48px;

        .questions-container {
            .question-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 28px;
                align-self: stretch;

                .question-number-container {
                    display: flex;
                    align-items: center;
                    gap: 18px;
                    align-self: stretch;

                    .number-container {
                        display: flex;
                        width: 30px;
                        height: 30px;
                        padding: 10px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        border-radius: 40px;
                        background: #E02216;

                        p {
                            color: #FFF;
                            font-family: roboto-bold;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 150%;
                        }
                    }

                    p {
                        color: #464646;
                        font-family: roboto-bold;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 150%;

                        span {
                            color: #464646;
                            font-family: roboto-light;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 150%;
                        }
                    }

                    @media (max-width: 768px) {
                        align-items: flex-start;
                    }
                }

                .error-msg {
                    color: red;
                }

                .options-container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 18px;

                    .option-container {
                        display: flex;
                        align-self: stretch;
                        align-items: center;
                        gap: 18px;

                        .input-checkbox-displayed {
                            display: flex;
                            width: 30px;
                            height: 30px;
                            padding: 10px;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            flex-shrink: 0;
                            border-radius: 8px;
                            border: 1px solid #D1D3D4;

                            &:hover {
                                cursor: pointer;
                                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
                            }

                            &.input-active {
                                background-image: url('./../../../../assets/screens/test-assets/checked.svg');
                                background-color: transparent;
                                background-position: center;
                                background-repeat: no-repeat;
                                stroke-width: 3px;
                                stroke: #99BF5F;
                                border: 1px solid #99BF5F;
                            }
                        }
                        .input-radio-displayed{
                            display: flex;
                            width: 30px;
                            height: 30px;
                            padding: 10px;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            flex-shrink: 0;
                            border-radius: 50%;
                            border: 1px solid #D1D3D4;

                            &:hover {
                                cursor: pointer;
                                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
                            }

                            &.input-active {
                                background-image: url('./../../../../assets/screens/test-assets/checked.svg');
                                background-color: transparent;
                                background-position: center;
                                background-repeat: no-repeat;
                                stroke-width: 3px;
                                stroke: #99BF5F;
                                border: 1px solid #99BF5F;
                            }
                        }

                        .option-label {
                            flex: 1 0 0;
                            color: #464646;
                            font-family: roboto-light;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 150%;
                        }
                    }
                }
            }

            @media (max-width: 768px) {
                gap: 24px;

                .question-container {
                    gap: 18px;

                    .question-number-container {
                        gap: 12px;

                        .number-container {
                            width: 24px;
                            height: 24px;
                            padding: 8px;

                            p {
                                font-size: 12px;
                            }
                        }

                        p {
                            font-size: 14px;

                            span {
                                font-size: 14px;
                            }
                        }
                    }

                    .options-container {
                        gap: 12px;

                        .option-container {
                            gap: 12px;

                            .input-checkbox-displayed,
                            .input-radio-displayed {
                                width: 24px;
                                height: 24px;
                                padding: 8px;
                            }

                            label {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }

    .buttons-container {
        display: flex;
        align-items: flex-start;
        gap: 16px;

        .btn-container {
            display: flex;
            padding: 10px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            border: 1px solid #E73529;
            background: #FFF;

            .arrow-image-container {
                background-position: center;
                background-repeat: no-repeat;
                width: 12px;
                height: 8px;
            }

            .right {
                background-image: url('./../../../../assets/screens/module-assets/red-right-arrow.svg');
            }

            .left {
                background-image: url('./../../../../assets/screens/module-assets/red-left-arrow.svg');
            }

            .btn-text {
                color: #E73529;
                font-family: poppins-medium;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                text-transform: uppercase;
            }

            &:hover {
                cursor: pointer;
                transition: ease-in-out 0.3s;
                background-color: #E73529;

                .btn-text {
                    color: #FFF;
                    transition: ease-in-out 0.3s;
                }

                .right {
                    background-image: url('./../../../../assets/screens/module-assets/white-right-arrow.svg');
                    transition: ease-in-out 0.3s;
                }

                .left {
                    background-image: url('./../../../../assets/screens/module-assets/white-left-arrow.svg');
                    transition: ease-in-out 0.1s;
                }
            }
        }

        .finish-btn {
            background-color: #E02216;

            .btn-text {
                color: #FFF;
            }

            .right {
                background-image: url('./../../../../assets/screens/module-assets/white-right-arrow.svg');
            }

            &:hover {
                opacity: 0.8;
                transition: ease-in-out 0.2s;
            }
        }
    }

    .result-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 18px;

        .percentage-info {
            font-family: poppins-bold;
            font-size: 64px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
        }

        p {
            color: #464646;
            font-family: roboto-light;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%;
        }

        .buttons-container {
            margin: 30px 0 0 0;
        }
    }
}