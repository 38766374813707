*, *::after, *::before{
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6,p,a,ul,ol,li{
  margin: 0 !important;
  padding: 0 !important;
}

body{
  position: relative;
  padding-bottom: 80px;
  min-height: 100vh;
}

@font-face {
  font-family: poppins-regular;
  src: url('../src/fonts/Poppins-Regular.woff');
}

@font-face {
  font-family: poppins-medium;
  src: url('../src/fonts/Poppins-Medium.woff');
}

@font-face {
  font-family: poppins-light;
  src: url('../src/fonts/Poppins-Light.woff');
}

@font-face {
  font-family: poppins-semibold;
  src: url('../src/fonts/Poppins-SemiBold.woff');
}

@font-face {
  font-family: poppins-bold;
  src: url('../src/fonts/Poppins-Bold.woff');
}

@font-face {
  font-family: roboto-regular;
  src: url('../src/fonts/Roboto-Regular.woff');
}

@font-face {
  font-family: roboto-medium;
  src: url('../src/fonts/Roboto-Medium.woff');
}

@font-face {
  font-family: roboto-light;
  src: url('../src/fonts/Roboto-Light.woff');
}

@font-face {
  font-family: roboto-bold;
  src: url('../src/fonts/Roboto-Bold.woff');
}

