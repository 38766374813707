.main-container{
    align-self: stretch;
    .list{
        display: flex;
        flex-direction: column;
        gap: 33px;
        .list-item{
            display: flex;
            align-items: flex-start;
            .list-bullet{
                margin-right: 12px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #E73529;
            }
            .list-item-text{
                flex: 1 0 0;
                color: #464646;
                font-family: roboto-light;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 150%; 
                .list-item-text-span{
                    color: #464646;
                    font-family: roboto-bold;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%;
                }
            }
        }
    }
}