.resetPassword-container{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    .resetPassword-form-container{
        display: flex;
        width: 368px;
        padding: 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        border-radius: 8px;
        border: 1px solid #D1D3D4;
        background: #FFF;
        .title{
            align-self: stretch;
            color: #464646;
            text-align: center;
            font-family: poppins-bold;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; 
        }
        .description{
            align-self: stretch;
            color: #464646;
            text-align: center;
            font-family: roboto-regular;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; 
            .signup-link{
                color: #E73529;
                text-decoration: none;
                &:hover{
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        .resetPassword-outside-form{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;
            .circle{
                display: flex;
                width: 40px;
                height: 40px;
                padding: 10px;
                justify-content: center;
                align-items: center;
                gap: 3px;
                border-radius: 40px;
                border: 1px solid #E73529;
            }
        }
        .resetPassword-form{
            display: flex;
            flex-direction: column;
            gap: 5px;
            align-self: stretch;
            gap: 15px;
            .error-msg{
                color: #E73529;
                font-size: 12px;
                padding-left:5px;
                margin-top: 10px !important;
            }
            .input-lg-container{
                display: flex;
                padding: 10px 24px;
                align-items: center;
                gap: 18px;
                align-self: stretch;
                border-radius: 8px;
                border: 1px solid #D1D3D4;
                background: #FFF;
                .lock-img{
                    cursor: pointer;
                    background-image: url('./../../assets/screens/loginAndSingup-assets/lock.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    width: 15.23px;
                    height: 18.76px;
                }
                input{
                    border: none;
                    outline: none;
                    flex: 1;
                }
                input::placeholder{
                    color: #D1D3D4;
                    font-family: poppins-regular;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; 
                }
            }
            .btn-container{
                display: flex;
                align-self: stretch;
                height: 40px;
                padding: 10px 24px;
                justify-content: center;
                align-items: center;
                gap: 18px;

                border-radius: 8px;
                border: 1px solid #D1D3D4;
                background: #E73529;

                &:hover{
                    cursor: pointer;
                }
                
                color: #FFF;

                text-align: center;
                font-family: poppins-regular;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
        }
    }
}