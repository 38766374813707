.no-scroll{
    overflow: hidden;
}
.modal-container{
    position: fixed;
    display: flex;
    justify-content: center;
    z-index: 999;
    width: 100%;
    height: 100vh;
    top: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
}